<template>
  <div class="data-box">
    <van-nav-bar
        title="用餐查询"
        left-text="返回"
        right-text=""
        left-arrow
        fixed
        z-index="1000"
        placeholder
        safe-area-inset-top
        @click-left="onClickLeft"
    />
    <div class="data-box-body">
      <div class="body-div-us">
        <div class="body-div-quick">
          <div class="quick">
            <span class="span-label">快捷添加</span>
            <span class="span-text" @click="selectSpecialDate(1)">今天</span>
            <span class="span-text" @click="selectSpecialDate(2)">活动期间</span>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">日期</div>
          <div class="span-input" @click="editDate">
            <span>{{ diningDate }}</span>
            <i class="iconfont icon-riqix"></i>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">餐点</div>
          <div class="span-input" @click="editDiningType">
            <span>{{ ["早餐","午餐","晚餐","宵夜","全部"][diningType] }}</span>
            <i class="iconfont icon-bianji2"></i>
          </div>
        </div>
        <div class="body-div-table">
          <dl>
            <tr>
              <dt v-for="(item,index) in keyValueTable" :key="index">{{ item.key }}</dt>
            </tr>
            <tr>
              <dd v-for="(item,index) in keyValueTable" :key="index">{{ item.value }}</dd>
            </tr>
          </dl>
        </div>
      </div>
      <div class="body-div">
        <div class="body-div-prompt align-center">
          点击餐厅进入订餐详情进行签收预定盒饭
        </div>
        <div class="body-div-table" v-if="restaurantList.length > 0">
          <dl>
            <tr>
              <dt>餐厅名称</dt>
              <dt>预定</dt>
              <dt>签收</dt>
              <dt>已派发</dt>
            </tr>
            <tr v-for="item in restaurantList" :key="item.diningType">
              <dd><span class="show-line1" style="color: #3476FF;padding:0px 2px;" @click="showDiningInfo(item)">{{ item.str }}</span></dd>
              <dd>{{ item.num1 ? item.num1 : 0 }}</dd>
              <dd>{{ item.num2 ? item.num2 : 0 }}</dd>
              <dd>{{ item.num3 ? item.num3 : 0 }}</dd>
            </tr>
            <tr class="table-last-tr">
              <dt style="border-bottom-left-radius: 10px;border-top-left-radius: 0px;">总计</dt>
              <dt>{{ total1 }}</dt>
              <dt>{{ total2 }}</dt>
              <dt style="border-bottom-right-radius: 10px;border-top-right-radius: 0px;">{{ total3 }}</dt>
            </tr>
          </dl>
        </div>
      </div>
    </div>
    <div class="data-box-foot">
      <van-button round block type="info" @click="startDinner">开始派餐</van-button>
    </div>
    <van-action-sheet v-model="showDiningType" :actions="mealsDiningType" @select="selectDiningType"/>
    <van-calendar v-model="showDiningDate" type="range" @confirm="selectDiningDate" :min-date="minDate" :max-date="maxDate" :default-date="defaultDate"/>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import {
  foodPlanStatistics
} from "@/api/index";

export default {
  name: "orgMeals_query",
  data() {
    return {
      diningDate: "",
      minDate: new Date(),
      maxDate: new Date(),
      defaultDate: null,
      diningType: null,
      showDiningDate: false,
      showDiningType: false,
      mealsDiningType: [
        {type: 0,name: "早餐"},
        {type: 1,name: "午餐"},
        {type: 2,name: "晚餐"},
        {type: 3,name: "宵夜"},
        {type: 4,name: "全部"}
      ],
      keyValueTable: [
        {key: "用餐人数",value: 0,type: 20},
        {key: "派餐人数",value: 0,type: 21},
        {key: "堂食人数",value: 0,type: 22},
        {key: "餐补人数",value: 0,type: 23},
        {key: "其他人数",value: 0,type: 24}
      ],
      restaurantList: [],
      total1: 0,
      total2: 0,
      total3: 0
    };
  },
  mounted() {
    let that = this;
    that.initData();
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal === true) {
        let that = this;
        that.initDetails();
      }
    },
  },
  methods:{
    initData(){
      let that = this;
      that.minDate = new Date();
      that.maxDate = new Date();
      that.defaultDate = [ that.minDate, that.maxDate ];
      that.diningType = 1;
      that.selectSpecialDate(1);
    },
    getFoodPlanStatistics(){
      let that = this;
      let params = {
        actId: that.$store.getters.getActivityId,
        startDate: that.diningDate.split(" - ")[0],
        endDate: that.diningDate.split(" - ")[1],
        relateType: that.diningType,
        companyId: that.$store.getters.getCompanyId,
        type: 3
      };
      foodPlanStatistics(params).then(res => {
        that.$tool.resValidation(res,() => {
          that.keyValueTable.forEach(item => {
            res.data.adminObjectVOS.forEach(obj => {
              if(item.type === obj.type){
                item.value = obj.num;
              }
            })
          })
          that.restaurantList = res.data.diningVOs;
          that.restaurantList.forEach(item => {
            that.total1 += item.num1;
            that.total2 += item.num2;
            that.total3 += item.num3;
          })
        })
      })
    },
    onClickLeft() {
      this.$router.back();
    },
    editDate(){
      let that = this;
      that.showDiningDate = true;
    },
    selectDiningDate(date){
      let that = this;
      let start = that.$tool.dateFormat(date[0]);
      let end = that.$tool.dateFormat(date[1]);
      that.showDiningDate = false;
      that.diningDate = start + " - " + end;
      that.getFoodPlanStatistics();
    },
    //type 1：今天；2：活动期间
    selectSpecialDate(type){
      let that = this;
      if(type === 1){
        let date = new Date();
        let start = that.$tool.dateFormat(date);
        let end = that.$tool.dateFormat(date);
        that.defaultDate = [ new Date(start),new Date(end) ];
        that.diningDate = start + " - " + end;
      }else{
        let actObjInfo = that.$store.getters.getOPDetails;
        that.defaultDate = [ new Date(actObjInfo.actStartDate), new Date(actObjInfo.actEndDate) ];
        that.diningDate = actObjInfo.actStartDate + " - " + actObjInfo.actEndDate;
      }
      that.getFoodPlanStatistics();
    },
    editDiningType(){
      let that = this;
      that.showDiningType = true;
    },
    selectDiningType(item){
      let that = this;
      that.diningType = item.type;
      that.showDiningType = false;
      that.getFoodPlanStatistics();
    },
    showDiningInfo(item){
      let that = this;
      that.$router.push({
        name: 'orgMeals_details',
        query:{
          id: item.id,
          name: item.str
        }
      });
    },
    startDinner(){
      let that = this;
      that.$dialog.confirm({
        message: '您不是该环节的负责人，确认代替执行么？',
      }).then(() => {
        this.$router.push('orgMeals_edit');
      }).catch(() => {
        // on cancel
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
.body-div-us{
  width: 90%;
  margin: 10px auto;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding-bottom: 16px;
  .body-div-quick{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .quick{
      width: 70%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      padding: 10px 10px;
      background-color: rgba(229,104,0,0.08);
      border-top-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    .span-label{
      color: #8B8FAD;
      font-size: 14px;
    }
    .span-text{
      padding: 6px 16px;
      font-size: 14px;
      color: #FD7300;
      background-color: #FFE1C8;
      border-radius: 16px;
    }
  }
  .body-div-item {
    width: 90%;
    margin: 16px 0px;
    padding: 0px 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    .span-key{
      width: 20%;
      color: #3E4576;
      font-size: 14px;
    }
    .span-input{
      width: 80%;
      background-color: #F6F9FF;
      border-radius: 20px;
      padding: 6px 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #3E4576;
      .placeholder{
        color: #8B8FAD;
      }
      .van-cell{
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        box-sizing: border-box;
        width: 100%;
        padding: 0px 10px;
        overflow: hidden;
        color: #323233;
        font-size: 14px;
        background-color: #F6F9FF;
      }
    }
    i{
      width: 10%;
      text-align: right;
      color: #3476FF;
      font-size: 20px;
    }
  }
  .body-div-table{
    width: 90%;
    margin: 10px 5% 0 5%;
    border-radius: 10px;
    border: 1px solid #C1D5FF;
    font-size: 12px;
    dl{
      border-radius: 10px;
      margin: 0px;
      tr {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        dt {
          width: 20%;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background-color: #f0f5ff;
          color: #8B8FAD;
          border-right: 1px dotted #C1D5FF;
        }
        dt:first-child{
          border-top-left-radius: 10px;
        }
        dt:last-child{
          border-top-right-radius: 10px;
          border-right: 0px;
        }
        dd {
          width: 25%;
          height: 40px;
          margin: 0px;
          line-height: 40px;
          text-align: center;
          border-right: 1px dotted #C1D5FF;
          border-top: 1px dotted #C1D5FF;
          a{
            color: #3476FF;
          }
        }
        dd:last-child{
          border-right: 0px;
        }
      }
    }
  }
}
</style>